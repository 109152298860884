<template>
  <div
    class="fixed z-40 top-0 bottom-0 left-0 right-0 h-full w-full bg-black bg-opacity-50 flex justify-end"
  >
    <div class="w-2/4 h-full"></div>
    <div class="w-2/4 h-full bg-white rounded-l-2xl p-10 overflow-auto">
      <div class="flex items-center justify-between">
        <h1 class="text-black font-bold text-xl">Corpse Profile</h1>
        <div
          class="bg-red-500 bg-opacity-30 p-5 rounded-full cursor-pointer"
          @click="$emit('close')"
        >
          <CloseIcon />
        </div>
      </div>
      <div class="flex justify-center items-center mb-5">
        <div class="bg-gray-100 p-5 rounded-full">
          <img :src="corpse.image" class="bg-gray-200 w-28 h-28 rounded-full" />
        </div>
      </div>
      <div class="border-b-2 border-gray-200 p-5 flex justify-between gap-4">
        <div class="w-1/2">
          <div class="text-sm text-gray-500">Name of Deceased</div>
          <div class="text-black text-2xl">{{ corpse.name_of_deceased }}</div>
        </div>
        <div class="w-1/2">
          <div class="text-sm text-gray-500">Age of Deceased</div>
          <div class="text-black text-2xl">{{ corpse.age_of_deceased }}</div>
        </div>
      </div>
      <div class="border-b-2 border-gray-200 p-5 flex justify-between gap-4">
        <div class="w-1/2">
          <div class="text-sm text-gray-500">Gender of Deceased</div>
          <div class="text-black text-2xl">{{ corpse.gender_of_deceased }}</div>
        </div>
        <div class="w-1/2">
          <div class="text-sm text-gray-500">Cause of Death</div>
          <div class="text-black text-2xl">{{ corpse.cause_of_death }}</div>
        </div>
      </div>
      <div class="border-b-2 border-gray-200 p-5 flex justify-between gap-4">
        <div class="w-1/2">
          <div class="text-sm text-gray-500">Contact Person’s Name</div>
          <div class="text-black text-2xl">{{ corpse.contact_person }}</div>
        </div>
        <div class="w-1/2">
          <div class="text-sm text-gray-500">Contact Person’s Phone</div>
          <div class="text-black text-2xl">
            {{ corpse.contact_person_phone }}
          </div>
        </div>
      </div>
      <div class="border-b-2 border-gray-200 p-5 flex justify-between gap-4">
        <div class="w-1/2">
          <div class="text-sm text-gray-500">Repatriation</div>
          <div class="text-black text-2xl">
            {{ corpse.repatriation === "1" ? "Yes" : "No" }}
          </div>
        </div>
        <div class="w-1/2">
          <div class="text-sm text-gray-500">Funeral Service</div>
          <div class="text-black text-2xl">
            {{ corpse.funeral_service === "1" ? "Yes" : "No" }}
          </div>
        </div>
      </div>
      <div class="border-b-2 border-gray-200 p-5 flex justify-between gap-4">
        <div class="w-1/2">
          <div class="text-sm text-gray-500">Embalment</div>
          <div class="text-black text-2xl">
            {{ corpse.embalmment_status === "1" ? "Yes" : "No" }}
          </div>
        </div>
        <div class="w-1/2">
          <div class="text-sm text-gray-500">Fridge Number</div>
          <div class="text-black text-2xl">
            {{ corpse.fridge_number }}
          </div>
        </div>
      </div>
      <div class="border-b-2 border-gray-200 p-5 flex justify-between gap-4">
        <div class="w-1/2">
          <div class="text-sm text-gray-500">Date of Addmission</div>
          <div class="text-black text-2xl">
            {{ formatDate(corpse.date_of_admission) }}
          </div>
        </div>
        <div class="w-1/2">
          <div class="text-sm text-gray-500">Time of Addmission</div>
          <div class="text-black text-2xl">
            {{ formatTime(corpse.time_of_admission) }}
          </div>
        </div>
      </div>
      <div class="border-b-2 border-gray-200 p-5 flex justify-between gap-4">
        <div class="w-1/2">
          <div class="text-sm text-gray-500">Corpse ID</div>
          <div class="text-black text-2xl">
            {{ corpse.corpse_id }}
          </div>
        </div>
        <div class="w-1/2">
          <div class="text-sm text-gray-500">Corpse Tag</div>
          <div class="text-black text-2xl">
            {{ corpse.corpse_tag }}
          </div>
        </div>
      </div>
      <div class="text-center">
        <button
          @click="$emit('proceed', corpse.corpse_id)"
          class="w-2/4 mt-14 mb-0 mx-auto text-white py-3 rounded-md bg-primary transition-all"
        >
          Proceed to Release
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "corpseProfile",
  components: {
    CloseIcon: () => import("@/assets/icons/CloseIcon.vue"),
  },
  props: {
    corpse: {
      required: true,
      type: Object,
    },
  },
  methods: {
    formatDate(date) {
      console.log(date);
      return moment(date).format("DD - MMM - Y");
    },
    formatTime(time) {
      return moment(time, "HH:mm").format("HH:MM A");
    },
  },
};
</script>
